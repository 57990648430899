import { Box, Card, CardActionArea, CardContent, Container, Grid, Typography, styled } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSessionContext } from "../../sessionStorage/session.hooks";
import { Banner } from "../../banner/banner.component";
import  { ModelDialog }  from "./addmodel.component";

const StyledCardActionArea = styled(CardActionArea)(() => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`);

export function SelectionGrid(): JSX.Element {

    return(
        <Grid container spacing="59px" justifyContent="center">
            <Grid item xs="auto">
                <ModelDialog addMode/>
            </Grid>
            <Grid item xs="auto">
                <Card className="AddMethodCard" style={{backgroundColor: "lightgrey"}}>
                    <StyledCardActionArea disabled onClick={() => {
                    console.log("Clone Existing");
                    }} sx={{ height: "100%"}}>

                        <CardContent style={{ display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
                            <ContentCopyIcon style={{fontSize: "100px"}}/>
                            <Typography gutterBottom variant="h1">
                                Clone Existing
                            </Typography>
                    </CardContent>
                    </StyledCardActionArea>
                </Card>
            </Grid>
            <Grid item xs="auto">
                <Card className="AddMethodCard" style={{backgroundColor: "lightgrey"}}>
                    <StyledCardActionArea disabled onClick={() => {
                    console.log("Import from Excel");
                    }} sx={{ height: "100%"}}>

                        <CardContent style={{ display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
                            <AttachFileIcon style={{fontSize: "100px"}}/>
                            <Typography gutterBottom variant="h1">
                                Import from Excel
                            </Typography>
                    </CardContent>
                    </StyledCardActionArea>
                </Card>
            </Grid>
        </Grid>
    );
}


export function AddModelPage(): JSX.Element {

    const {
        sessionProduct
      } = useSessionContext();

    return (
        <Box>
            <Container maxWidth="xl">

                {sessionProduct && (
                    <Box>
                        <Banner firstLine={`${sessionProduct.name} - Add Threat Model`} secondLine="Pick a method" alignToLeft={false} />
                        <SelectionGrid/>
                    </Box>
                )}

            </Container>

    </Box>
    );
}
