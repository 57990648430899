import { Box, Container, Typography, Link, Grid } from "@mui/material";
import { Banner } from "../banner/banner.component";

export function Home(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Hello" secondLine="Welcome to the THEREMIN platform" alignToLeft={false}/>
            <Container maxWidth="xl">
                <Box style={{ maxWidth: "60%", minHeight: 170 }} sx={{ pt: 2 }}>
                    <Typography variant="h1" sx={{ mb: 3 }}>
                        What is threat modeling?
                    </Typography>
                    <Typography component='div' className="homePageDescription" sx={{ mb: 4 }}>
                        <p>
                            Threat modeling is a systematic approach for identifying and analysing cybersecurity risks and assessing
                            the planned or implemented protections against relevant threats.
                        </p>

                        <p className="homePageList">
                            A threat model is a tool for clarifying and prioritising security goals by:
                            <li>reviewing the solution for potential weak points and identifying those that might be attacked on purpose or be vulnerable to accidental security incidents</li>
                            <li>clarifying the roles and responsibilities of the solution in the larger context to list outcomes that should not happen and their potential impact</li>
                            <li>identifying and prioritising scenarios linking weaknesses in the solution with the unwanted outcomes, taking feasibility and the target security level into consideration</li>
                            <li>reviewing the coverage and effectiveness of security protections for the relevant scenario, identifying any gaps to be fixed and risks to be managed </li>
                        </p>

                        <p>
                            Creating a threat model is a requirement of the Minimum Security Procedure:
                        </p>
                        <blockquote><q>All new products and major product revisions shall conduct and document a threat model.</q></blockquote>

                    </Typography>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs>
                            <Typography component='div' className="homePageText">
                            To learn more about threat modeling:
                            <ul className="homePageList">
                                <li><Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/display/PMSP/2.4+Recipe+-+Threat+Model">MSP - 2.4 Recipe - Threat Model</Link></li>
                            </ul>
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
            </Container>
        </Box>
    );
}
