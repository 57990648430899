import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ComputerIcon from '@mui/icons-material/Computer';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Container, Grid, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { useModelArchitectureInfo} from './architecture.hooks';
import { HardwareUnit, ModelArchitectureInfo, ZoneArchitectureInfo } from "./architecture.types";
import { DeviceDialog } from "../device/device.component";
import {ZoneDialog} from "../zone/zone.component";
import { DropDownItem } from "../detailsDialog/detailsDialog.types";
import { ThreatModelInfo } from '../threatmodel/threatmodel.types';

interface ZoneArchitectureProps {
    data: ZoneArchitectureInfo;
}


interface DetailItemProps {
    device: HardwareUnit;
}

interface LevelArchitectureProps {
    data: ModelArchitectureInfo;
}

const LevelColorCodes: string[] = [
    '#325555',
    '#3c8278',
    '#649696',
    '#789678',
    '#91b473',
    '#c8d7a0',
]

export const PurdueLevels: DropDownItem[] = [
    {id: 0, name: "Level 0 (Field units)"},
    {id: 1, name: "Level 1 (Process controls)"},
    {id: 2, name: "Level 2 (Local HMI)"},
    {id: 3, name: "Level 3 (Management / site supervisory)"},
    {id: 4, name: "Level 4 (Enterprise IT)"},
    {id: 5, name: "Level 5 (Internet / external)"},
]

export function DetailItem({device}: DetailItemProps): JSX.Element {

    return (
        <Button sx={{
            width: "100%"
        }} variant="outlined" startIcon={<ComputerIcon />}>
            <Typography>{device.name}</Typography>
        </Button>
    );
}


export function ZoneArchitecture({data}: ZoneArchitectureProps): JSX.Element {

    return (
        <Box sx={{
            paddingBottom: "25px"
        }}>
            <Accordion expanded>
                        <AccordionSummary
                        sx={{
                            backgroundColor: "lightblue"
                        }} aria-controls="panel1d-content" id="panel1d-header">
                        <Grid container justifyContent="flex-start">
                            <Typography>{data.zone_name}</Typography>
                        </Grid>

                        <Grid container justifyContent="flex-end">
                            <ZoneDialog addMode={false} zoneId={data.zone_id}/>
                        </Grid>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {data.hardware_units.map((d) => (
                                    <Grid item xs={6}>
                                        <DeviceDialog addMode={false} deviceId={d.id} deviceName={d.name}/>
                                    </Grid>
                                ))}
                                <Grid item xs={6} justifyContent="flex-end">
                                        <DeviceDialog addMode zoneId={data.zone_id}/>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
        </Box>
    );
}


export function PurdueLevelGrid({data}: LevelArchitectureProps): JSX.Element {
    return (
        <Box sx={{
            paddingBottom: "25px"
        }}>
            <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        sx={{
                            backgroundColor: LevelColorCodes[data.purdue_lvl]
                        }} aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{data.lvl_name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            backgroundColor: LevelColorCodes[data.purdue_lvl]
                        }}>
                            <Grid container spacing={4}>
                                {data.zones.map((d) => (
                                    <Grid item xs={4}>
                                        <ZoneArchitecture
                                        data={d}
                                        />
                                    </Grid>

                                ))}

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
        </Box>
    );

}


export function Toolbar(): JSX.Element {
    return(
        <Container className="bannerContainer" maxWidth={false} disableGutters>
            <Container maxWidth={false}>
                <Box className="banner" maxWidth="xl">
                    <Typography
                        className="bannerText"
                        style={{lineHeight: 1.4}}
                        variant="h1"
                        sx={{paddingLeft: 0}}>
                        Model Architecture
                    </Typography>
                    <Grid container justifyContent="flex-end">
                        <ZoneDialog addMode/>
                    </Grid>

                </Box>
            </Container>

        </Container>
    );
}

interface ArchitectureProps {
    modelData: ThreatModelInfo;
  }

export function ArchitectureGrid({modelData}: ArchitectureProps): JSX.Element {
    if (modelData) {
        const ArchitectureInfo = useModelArchitectureInfo(modelData.id);

        return (
            <Box>
                {ArchitectureInfo.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {!ArchitectureInfo.isError && ArchitectureInfo.data && (
                    <Box>
                        {ArchitectureInfo.data.map((d) => (
                            <PurdueLevelGrid
                                data={d}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        );
    }
    return (
        <Box>
            <Alert severity="error" sx={{ mb: 2 }}>Please select model</Alert>
        </Box>
    )

}

export function Architecture({modelData}: ArchitectureProps): JSX.Element {
    return (
        <Box maxWidth="xl">
            <Toolbar/>
            <ArchitectureGrid modelData={modelData}/>

        </Box>
    );
}
