import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Container, Tab, Tabs, TextField, Typography } from "@mui/material";
import { getProductInfo, getProductsInfo } from './product.hooks';
import { ProductInfo } from './product.types';
import { SelectionGrid } from "../selectionGrid/selectiongrid.component";
import { Banner } from "../banner/banner.component";
import { useSessionContext } from "../sessionStorage/session.hooks";
import { ModelSelectionGrid } from "../threatmodel/threatmodel.component";
import { NotFoundPage } from "../navigation/errorPages.component";



export function ProductSelectionGrid(): JSX.Element {

    const productInfo = getProductsInfo();
    const navigate = useNavigate();

    const {
        setSessionProduct
      } = useSessionContext();

    return (
        <Box>
            <Banner firstLine="Product Selection" secondLine="Welcome to the Theremin NG interface. Please start by selecting a product" alignToLeft={false} />
            <Container maxWidth="xl">

                {productInfo.isLoading && (
                    <Alert severity="info" sx={{mb: 2}}>Loading products...</Alert>
                )}
                {productInfo.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {!productInfo.isLoading && !productInfo.isError && (
                    <Box>
                        <Box style={{height: 670, width: "100%"}}
                            sx={{
                            '& .grid-src-header': {
                                borderBottom: '5px solid #053B57 !important'
                            },
                            '& .grid-link-header': {
                                borderBottom: '5px solid #FFAA7A !important',
                                marginLeft: '3px'
                            },
                            '& .grid-proto-header': {
                                borderBottom: '5px solid #FFAA7A !important',
                                marginRight: '3px'
                            },
                            '& .grid-dst-header': {
                                borderBottom: '5px solid #B8D9AF !important'
                            },
                            '& .grid-act-header': {
                                borderBottom: '5px solid #FFDFCF !important',
                                marginLeft: '3px'
                            },
                            '& .grid-desc-header': {
                                borderBottom: '5px solid #FFDFCF !important'
                            },
                        }}>
                            <SelectionGrid
                            data={productInfo.data}
                            canAdd={false}
                            canEdit={false}
                            onItemSelected={(product) =>
                                {
                                    if ((product as ProductInfo).mycyber_id !== undefined) {
                                        setSessionProduct(product as ProductInfo);
                                        navigate({pathname: `/products/${(product as ProductInfo).mycyber_id}`})
                                    }
                                }
                            }
                            />
                        </Box>
                    </Box>
                )}
            </Container>

    </Box>
    );
}


interface TabPanelProps {
    index: number;
    value: number;
  }


export function CustomTabPanel({ value, index}: TabPanelProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>Not implemented</Typography>
          </Box>
        )}
        </Container>
    );
  }


  interface ProductTabProps {
    index: number;
    value: number;
    productData: ProductInfo;
  }


export function ModelSelectionTabPanel({ value, index, productData}: ProductTabProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
          <Box sx={{ p: 3 }}>
            <ModelSelectionGrid productId={productData.mycyber_id}/>
          </Box>
        )}
        </Container>
    );
  }

  interface InformationTabItemProps {
    title: string;
    value: string;
  }

  export function InformationTabItem({title, value}: InformationTabItemProps): JSX.Element {

    const fieldTitle = <strong>{title}</strong>

    return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        <TextField
        variant="outlined"
        size="small"
        label=""
        fullWidth
        multiline
        sx={{marginBottom: 3}}
        value={value}
        aria-readonly
        />
        <br/>
    </Box>
}

  export function InformationTabPanel({ value, index, productData}: ProductTabProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography sx={{marginBottom: 3}} variant="h1" fontWeight="normal">Digital Product Summary from MyCyber</Typography>
            <InformationTabItem title="Product Name" value={productData.name}/>
            <InformationTabItem title="Product Description" value={productData.description}/>
            <InformationTabItem title="Commercial Product Description" value={productData.commercial_description}/>
            <InformationTabItem title="Business Unit" value={productData.business_unit.name}/>
            <InformationTabItem title="Life Cycle Stage" value={productData.life_cycle_stage}/>
            <InformationTabItem title="Product Owner" value={productData.product_owner}/>
            <InformationTabItem title="Product Manager" value={productData.product_manager}/>
          </Box>
        )}
        </Container>
    );
  }

  export function ProductPage(): JSX.Element {

    const {pathname} = window.location;
    const parts = pathname.split("/");
    const productId = parts[2];
    const [errorEncountered, setErrorEncountered] = React.useState(false);
    const {
        setProductName,
      } = useSessionContext();


    const { isLoading, isError, data } = getProductInfo(+productId, !errorEncountered);
    setProductName(data ? data.name : "");
      if (isError && !errorEncountered) {
        setErrorEncountered(true);
      }
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setValue(newValue);
      };
    return (
        <Box>
            <Container maxWidth="xl">
            {isLoading && (
                    <Alert severity="info" sx={{mb: 2}}>Loading product data...</Alert>
                )}
                {errorEncountered && (
                    <NotFoundPage text="No data found or not a product member"/>
                )}
                {!isLoading && data && (
                    <Box>
                    <Banner firstLine={data.name} secondLine="Select or add a Threat Model" alignToLeft={false} />
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="Threat model tabs">
                                <Tab label="Threat Models"/>
                                <Tab label="Product Information" />
                                <Tab label="Files And Links" />
                            </Tabs>
                            </Box>
                            <ModelSelectionTabPanel value={value} index={0} productData={data}/>
                            <InformationTabPanel value={value} index={1} productData={data}/>
                            <CustomTabPanel value={value} index={2}/>
                    </Box>

                </Box>
                )}

            </Container>

    </Box>
    );
}
