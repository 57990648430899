import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Box, Typography} from "@mui/material";

interface NotFoundPageProps {
    text: string;
}
export function NotFoundPage({text}: NotFoundPageProps): JSX.Element | null {
    return <Box style={{ display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
        <ErrorOutlineIcon style={{fontSize: "100px"}}/>
        <Typography variant="h1">
            {text}
        </Typography>
    </Box>
}
