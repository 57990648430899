import {Box, Container, Typography} from "@mui/material";

interface BannerProps {
    firstLine: string;
    secondLine: string;
    alignToLeft: boolean;
}

export function Banner({firstLine, secondLine, alignToLeft}: BannerProps): JSX.Element {
    return(
        <Container className="bannerContainer" maxWidth={false} disableGutters>
            <Container maxWidth={alignToLeft ? false : "xl"}>
                <Box className="banner" maxWidth="xl">
                    <Typography
                        className="bannerText"
                        style={{lineHeight: 1.4}}
                        variant="h1"
                        sx={{paddingLeft: 0}}>
                        {firstLine}
                        <br/>
                        {secondLine}
                    </Typography>
                </Box>
            </Container>

        </Container>
    );
}
