import { useLocation, useNavigate } from "react-router-dom";
import {Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, Divider, Grid, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { ProductInfo } from "../product/product.types";
import { ThreatModelInfo } from "../threatmodel/threatmodel.types";


interface SelectionGridProps {
    data: ProductInfo[] | ThreatModelInfo[] | undefined;
    canEdit: boolean;
    canAdd: boolean;
    onItemSelected: (item: ProductInfo | ThreatModelInfo) => void;
}

interface GridItemProps {
    data: ProductInfo | ThreatModelInfo;
    canEdit: boolean;
    onItemSelected: (item: ProductInfo | ThreatModelInfo) => void;
}

interface CardContentProps {
    data: ProductInfo | ThreatModelInfo;
}

interface CardDividerProps {
  dividerColor: string;
}


export function CardDivider({dividerColor}: CardDividerProps): JSX.Element {
  return (
      <Divider sx={{ borderBottomWidth: 5, bgcolor: dividerColor }} variant="middle"/>
    );
}


export function CardContentData({data}: CardContentProps): JSX.Element {
    if ((data as ProductInfo).mycyber_id !== undefined) {
        return (
            <Box>
                <Typography padding={2} variant="h1" fontWeight="normal">
                      {data.name}
                  </Typography>
                  <CardDivider dividerColor="#FF7321"/>
                  <Typography padding={2}>
                    {(data as ProductInfo).description}
                  </Typography>
                  <Typography padding={2}>
                    {(data as ProductInfo).threat_model_count} Threat Models
                  </Typography>
            </Box>
          );
    }
    return (
        <Box>
            <Typography padding={2} variant="h1" fontWeight="normal">
              {data.name}
              </Typography>
              <CardDivider dividerColor="#086795"/>
              <Typography padding={2}>
                {(data as ThreatModelInfo).description}
              </Typography>
              <Typography padding={2}>
                Modified: {(data as ThreatModelInfo).last_updated}
              </Typography>
        </Box>
      );
  }


export function GridItem({data, canEdit, onItemSelected}: GridItemProps): JSX.Element {
    return (
        <Card className="selectionGridCard" >
          <CardActionArea onClick={() => {
          onItemSelected(data);
        }} sx={{ height: "100%"}}>

            <CardContent sx={{ height: "100%"}}>
              <CardContentData data={data}/>
            </CardContent>
          </CardActionArea>
          <CardActions sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              p: 0,
              }}>
              {canEdit && (
                  <Button>Edit</Button>
              )}
          </CardActions>
        </Card>
      );
  }

  export function AddItem(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    return (
      <Card className="selectionGridCard">
        <CardActionArea sx={{ height: "100%"}}
        onClick={() => {
          navigate({pathname: `${location.pathname}/add`});
        }}>
          <CardContent style={{ display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
            <AddIcon style={{fontSize: "100px"}}/>
            <Typography gutterBottom variant="h1">
              Add New Threat Model
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

export function SelectionGrid({data, canEdit, canAdd, onItemSelected}: SelectionGridProps): JSX.Element {

    if (data !== undefined) {
        return(
            <Grid container spacing={4}>
                {data.map((item) => (
                    <Grid item xs={4}>
                        <GridItem
                        data={item}
                        canEdit={canEdit}
                        onItemSelected={onItemSelected}
                        />
                    </Grid>

                ))}
                {canAdd && (
                    <Grid item xs={4}>
                        <AddItem/>
                    </Grid>

                )}
            </Grid>
        );
    }
    return (
        <Box>
            <Alert severity="error" sx={{ mb: 2 }}>Undefined grid data</Alert>
        </Box>
    );
}
