import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { getThreatModelInfo, getThreatModelsInfo } from './threatmodel.hooks';
import { SelectionGrid } from "../selectionGrid/selectiongrid.component";
import { ThreatModelInfo } from "./threatmodel.types";
import { useSessionContext } from "../sessionStorage/session.hooks";
import { Banner } from "../banner/banner.component";
import { ArchitectureTabPanel } from "./architectureTab/architectureTab.component";
import { InformationTabPanel } from "./informationTab/informationTab.component";
import { getProductInfo } from "../product/product.hooks";
import { NotFoundPage } from "../navigation/errorPages.component";




interface ModelProps {
    productId: number;
}


export function ModelSelectionGrid({productId}: ModelProps): JSX.Element {

    const modelInfo = getThreatModelsInfo(productId);
    const navigate = useNavigate();
    const location = useLocation();

    const {
        setSessionModel,
      } = useSessionContext();

    return (
        <Box>
            <Container maxWidth="xl">

                {modelInfo.isLoading && (
                    <Alert severity="info" sx={{mb: 2}}>Loading models...</Alert>
                )}
                {modelInfo.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {!modelInfo.isLoading && !modelInfo.isError && (
                    <Box>
                        <Box style={{height: 670, width: "100%"}}
                            sx={{
                            '& .grid-src-header': {
                                borderBottom: '5px solid #053B57 !important'
                            },
                            '& .grid-link-header': {
                                borderBottom: '5px solid #FFAA7A !important',
                                marginLeft: '3px'
                            },
                            '& .grid-proto-header': {
                                borderBottom: '5px solid #FFAA7A !important',
                                marginRight: '3px'
                            },
                            '& .grid-dst-header': {
                                borderBottom: '5px solid #B8D9AF !important'
                            },
                            '& .grid-act-header': {
                                borderBottom: '5px solid #FFDFCF !important',
                                marginLeft: '3px'
                            },
                            '& .grid-desc-header': {
                                borderBottom: '5px solid #FFDFCF !important'
                            },
                        }}>
                            <SelectionGrid
                            data={modelInfo.data}
                            canAdd
                            canEdit
                            onItemSelected={(model) =>
                                {
                                    if ((model as ThreatModelInfo).id !== undefined) {
                                        setSessionModel(model as ThreatModelInfo);
                                        navigate({pathname: `${location.pathname}/${(model as ThreatModelInfo).id}`})
                                        }
                                }
                            }
                            />
                        </Box>
                    </Box>
                )}
            </Container>

    </Box>
    );
}


interface TabPanelProps {
    index: number;
    value: number;
  }


export function CustomTabPanel({ value, index}: TabPanelProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>Not implemented</Typography>
          </Box>
        )}
        </Container>
    );
  }

  export function ModelPage(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");
    const productId = parts[2];
    const modelId = parts[3];
    const [errorEncountered, setErrorEncountered] = React.useState(false);

    const {
        setProductName,
        setModelName
      } = useSessionContext();


    const productData = getProductInfo(+productId);
    setProductName(productData.data ? productData.data.name : "");
    const { isLoading, isError, data } = getThreatModelInfo(+modelId, true);
    setModelName(data ? data.name : "");
    const [value, setValue] = React.useState(0);

    if (productData.isError && !errorEncountered) {
        setErrorEncountered(true);
      }
  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

    return (
        <Box>
            <Container maxWidth="xl">
                {productData.isLoading && (
                    <Alert severity="info" sx={{mb: 2}}>Loading product data...</Alert>
                )}
                {errorEncountered && (
                    <NotFoundPage text="No data found or not a product member"/>
                )}
                {productData.isSuccess && (
                    <Box>
                        {isLoading && (
                    <Alert severity="info" sx={{mb: 2}}>Loading model data...</Alert>
                )}
                {isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {!isLoading && data && (
                    <Box>
                    <Banner firstLine={data.name} secondLine="" alignToLeft={false} />
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Threat model tabs">
                            <Tab label="Architecture"/>
                            <Tab label="Threats and Mitigations" />
                            <Tab label="Basic Information" />
                            <Tab label="Snapshots" />
                        </Tabs>
                        </Box>
                        <ArchitectureTabPanel value={value} index={0} data={data}/>
                        <CustomTabPanel value={value} index={1}/>
                        <InformationTabPanel value={value} index={2} data={data}/>
                        <CustomTabPanel value={value} index={3}/>
                </Box>
                )}
                    </Box>
                )}


            </Container>

    </Box>
    );
}
