import {
  useLocation,
} from 'react-router-dom';
import { Breadcrumbs, Container } from "@mui/material";
import { ButtonBase } from "../navigation/buttonBase.component";
import { useSessionContext } from '../sessionStorage/session.hooks';


export function Breadcrumb(): JSX.Element {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const {
    currentProductName,
    currentModelName
  } = useSessionContext();
  return (
      <Container className="breadcrumbs" maxWidth="xl">
          <Breadcrumbs style={{
        paddingTop: 4,
    }}>
          <ButtonBase text="Products" path="/products"/>
      {pathnames.slice(1).map((value, index) => {
        const to = `/${pathnames.slice(0, index + 2).join('/')}`;
        let buttonText = index === 0 ? currentProductName : currentModelName;
        if (Number.isNaN(+value)) {
          buttonText = value;
        }
        return (

          <ButtonBase text={buttonText} path={to}/>
        )
      })}
    </Breadcrumbs>
      </Container>
  );
}
