import { Box, Container, Tab, Tabs } from "@mui/material";
import React from "react";
import Upload from "../../upload/upload.component";
import { Dataflow } from "../../dataflow/dataflow.component";
import { Architecture } from "../../architecture/architecture.component";
import { ThreatModelInfo } from "../threatmodel.types";

interface ArchitectureTabProps {
    index: number;
    value: number;
    data: ThreatModelInfo;
}


interface TabPanelProps {
    index: number;
    value: number;
    type: string;
    data: ThreatModelInfo;
}


export function TabPanel({ value, index, type, data}: TabPanelProps): JSX.Element {
    if (value === index) {
        return (
          <Box minWidth="100%" sx={{ p: 3 }}>
            {type === "diagram" && (
                <Architecture modelData={data}/>
            )}
            {type === "dataflows" && (
                <Dataflow modelData={data}/>
            )}
            {type === "import" && (
                <Upload/>
            )}
          </Box>
        );
    }
    return (
        <Box/>
    );
}

interface VerticalTabProps {
  data: ThreatModelInfo;
}

export default function VerticalTabs({data}: VerticalTabProps): JSX.Element   {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number):void => {
      setValue(newValue);
    };

    return (
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Architecture tabs"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: 100 }}
        >
          <Tab label="Diagram"/>
          <Tab label="Dataflows" />
          <Tab label="Import"  />
        </Tabs>
        <TabPanel value={value} index={0} type="diagram" data={data}/>
        <TabPanel value={value} index={1} type="dataflows" data={data}/>
        <TabPanel value={value} index={2} type="import" data={data}/>
      </Box>
    );
  }

export function ArchitectureTabPanel({value, index, data }: ArchitectureTabProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
                <Box sx={{ paddingTop: 3 }}>
                    <VerticalTabs data={data}/>
                </Box>
                )}
        </Container>

    );
  }
