import React, { MouseEventHandler, useMemo, useState } from 'react';
import { Box, Button, InputAdornment, ListSubheader, MenuItem, Select, SelectChangeEvent, TextField, Typography} from '@mui/material';
import { DropDownItem } from '../detailsDialog.types';


interface InfoFieldPairProps {
    title: string;
    name: string;
    data: string;
    multiline?: boolean;
    editing: boolean;
    updating: boolean;
    editable?: boolean
    mandatory?: boolean
    hidden?: boolean
    valueChanged?: (value: string) => void;
    helperText?: string;
    validValue?: boolean;
}

const mandatoryMarker = <span style={{color: "#f80808"}}> *</span>
export function InfoFieldPair({title, name, data, multiline = false, editing, updating, editable = true, mandatory = false, hidden = false, valueChanged, helperText = "", validValue = true}: InfoFieldPairProps): JSX.Element {
    const [inputValue, setInputValue] = React.useState('')

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (valueChanged) {
                valueChanged(inputValue)
            }

        }, 500)

        return () => clearTimeout(timer)
    }, [inputValue])

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {data === null ? ("-") : (data)}
    </Typography>

    const input = <TextField
        id={name}
        name={name}
        variant="filled"
        size="small"
        label=""
        fullWidth
        multiline={multiline}
        sx={{marginBottom: 3}}
        defaultValue={data}
        disabled={updating}
        required={mandatory}
        helperText={helperText}
        FormHelperTextProps={{ sx: { color: validValue ? 'green' : 'red' } }}
        onChange={e => {
            setInputValue(e.target.value)
            }}
        InputProps={{
            onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {e.stopPropagation();},
            }}
        />

    const field = editing && editable ? input : text;
    const fieldTitle = <strong>{title}{(mandatory && editing && mandatoryMarker)}</strong>

    return <Box sx={{ visibility: hidden ? 'hidden' : 'visible' }}>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        {field}
        <br/>
    </Box>
}


interface InfoObjectDropdownPairProps {
    title: string;
    name: string;
    currentId: number;
    dropdownOptions: DropDownItem[];
    editing: boolean;
    updating: boolean;
    editable?: boolean
    onChangeCallback?: (newValue: DropDownItem) => void;
    mandatory?: boolean
}

const containsText = (text: string, search: string): boolean =>
    text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const DropdownMenuProps = {
    autoFocus: false,
    PaperProps: { sx: { maxHeight: 300, maxWidth: 400 } },
    sx: {
        '& .MuiMenuItem-root': {
            fontSize: 16,
            fontWeight: "regular",
        },
    }
};

export function InfoObjectDropdownPair({title, name, currentId, dropdownOptions, editing, updating, editable = true, onChangeCallback, mandatory = false}: InfoObjectDropdownPairProps): JSX.Element {
    const fieldTitle = <strong>{title}{(mandatory && editing && mandatoryMarker)}</strong>
    if (dropdownOptions.length === 0) {
        return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {currentId}
    </Typography>
        <br/>
    </Box>
    }
    const index = dropdownOptions.findIndex(option => option.id === currentId);

    const selectedItem = index === -1 ? dropdownOptions[0] : dropdownOptions[index];
    const [selected, setSelected] = useState<DropDownItem>(selectedItem);
    const [searchText, setSearchText] = useState("");

    const displayedItems = useMemo(
        () => dropdownOptions.filter((option) => containsText(option.name, searchText)),
        [dropdownOptions, searchText]
        );

    const text = <Box>
            <Typography
            style={{
                fontSize: "medium",
                whiteSpace: "normal",
                wordWrap: "break-word"}}
            sx={{
                marginTop: 0,
                marginBottom: 1.4,
                marginLeft: 0,
                marginRight: 0,
                padding: 0}}>
            {selectedItem.name}
        </Typography>
        <TextField
        id={name}
        name={name}
        disabled
        sx={{ display: 'none' }}
        value={selectedItem.id}/>
        </Box>

    const dropdown = <Select
        MenuProps={DropdownMenuProps}
        id={name}
        name={name}
        label={name}
        disabled={updating}
        defaultValue={selected}
        renderValue={() => selected.name}
        sx={{ minWidth: 200,
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0 }}
        onChange={(event: SelectChangeEvent<DropDownItem>) => {
            const item = dropdownOptions.find(option => option.id === Number(event.target.value));
            if (item) {
                setSelected(item);
                if(onChangeCallback !== undefined) {
                    onChangeCallback(item);
                }
            }

        }}
        onClose={() => setSearchText("")}
    >
        <ListSubheader>
            <TextField
                size="small"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start"/>
                )
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                }
                }}
            />
        </ListSubheader>
        {displayedItems.map((option) => (
            <MenuItem key={option.id} value={option.id} style={{color: option.name === "" ? "#a0a0a0" : "#000000"}}>
              {option.name === "" ? "none" : option.name}
            </MenuItem >
          ))}
    </Select>

    const field = editing && editable ? dropdown : text;


    return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        {field}
        <br/>
    </Box>
}


interface InfoDropdownPairProps {
    title: string;
    name: string;
    data: string;
    dropdownOptions: string[];
    editing: boolean;
    updating: boolean;
    editable?: boolean
    onChangeCallback?: (newValue: string) => void;
    mandatory?: boolean
}


export function InfoDropdownPair({title, name, data, dropdownOptions, editing, updating, editable = true, onChangeCallback, mandatory = false}: InfoDropdownPairProps): JSX.Element {

    const [selected, setSelected] = useState<string>(data);
    if (dropdownOptions.indexOf(selected) === -1) {
        setSelected(dropdownOptions[0]);
    }

    const text = <Typography
        style={{
            fontSize: "medium",
            whiteSpace: "normal",
            wordWrap: "break-word"}}
        sx={{
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0}}>
        {selected}
    </Typography>


    const dropdown = <Select
        id={name}
        name={name}
        value={selected}
        label={name}
        disabled={updating}
        defaultValue={selected}
        sx={{ minWidth: 150,
            marginTop: 0,
            marginBottom: 1.4,
            marginLeft: 0,
            marginRight: 0,
            padding: 0 }}
        onChange={(event: SelectChangeEvent<string>) => {
            setSelected(event.target.value);
            if(onChangeCallback !== undefined) {
                onChangeCallback(event.target.value);
            }
        }}
    >
        {dropdownOptions.map((option) => (
            <MenuItem key={option} value={option} style={{color: option === "" ? "#a0a0a0" : "#000000"}}>
              {option === "" ? "none" : option}
            </MenuItem >
          ))}
    </Select>

    const field = editing && editable ? dropdown : text;
    const fieldTitle = <strong>{title}{(mandatory && editing && mandatoryMarker)}</strong>

    return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            {fieldTitle}
        </Typography>
        {field}
        <br/>
    </Box>
}


interface EditButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>
    updating: boolean;
    enabled?: boolean;
}


export function EditButton({onClick, updating, enabled}: EditButtonProps): JSX.Element {

    return (
        <Button
        onClick={onClick}
            sx={{ margin: 1 }}
            style={{
                color: "#ffffff",
                backgroundColor: updating || !enabled ? "#99b7c4" : "#0f334a" }}
            variant="text"
            type="submit"
            disableRipple
            disabled={updating || !enabled}>
            Edit
        </Button>
    );
}
