import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button } from "@mui/material";
import DetailsDialog from "../detailsDialog/detailsDialog.component";
import { DetailsData } from "../detailsDialog/detailsDialog.types";
import { InfoFieldPair, InfoObjectDropdownPair } from "../detailsDialog/detailComponents/detailsDialogComponents.component";
import { ZoneInfo } from "./zone.types";
import { getZoneInfo } from "./zone.hooks";
import { PurdueLevels } from "../architecture/architecture.component";




interface ZoneDialogProps {
    addMode: boolean;
    zoneId?: number;
}

interface ZoneFormProps {
    editing: boolean;
    data?: ZoneInfo;
}


interface ZoneFieldsProps {
    editing: boolean;
    zone: ZoneInfo | undefined;
}


export function ZoneFields({editing, zone}: ZoneFieldsProps): JSX.Element {
    return(
        <form id="zone-form">
            <InfoFieldPair title="Label" name="label" data={zone !== undefined ? zone.name : ""} updating={false} editing={editing} mandatory/>
            <InfoFieldPair title="Description" name="description" data={zone !== undefined ? zone.description : ""} updating={false} editing={editing}/>
            <InfoObjectDropdownPair title="Purdue Level" name="purdue" currentId={zone !== undefined ? zone.architecture_layer : 0} dropdownOptions={PurdueLevels} updating={false} editing={editing} mandatory/>
            <InfoFieldPair title="GUID" name="guid" data={zone !== undefined ? zone.guid : ""} updating={false} editing={false} editable={false} hidden={zone === undefined}/>
        </form>
    );
}


export function ZoneForm({editing, data}: ZoneFormProps): JSX.Element {
    if (data) {
        return(
            <Box>
                <ZoneFields editing={editing} zone={data}/>
            </Box>
        );
    }
    return(
        <ZoneFields editing={editing} zone={undefined}/>
    );
}

export function ZoneDialog({addMode, zoneId}: ZoneDialogProps): JSX.Element {
    const [dialogOpen, SetDialogOpen] = useState(false);

    const handleOpen = ():void => {
        SetDialogOpen(true);
      };
    const handleClose = ():void => {
        SetDialogOpen(false);
    };



    const detailsData: DetailsData = {
        formName: "zone-form",
        path: addMode ? "architecture/zone/add" : "architecture/zone/update",
        deletePath: "architecture/zone/delete",
        title: addMode ? "Add Zone" : "Zone Details",
        addMode,
        zoneId: zoneId !== undefined ? zoneId : -1,
        mode: "dialog",
      };

    if (!addMode && zoneId) {
        const { isLoading, data } = getZoneInfo(zoneId, dialogOpen);
        return(
            <Box>
                <Button
                    onClick={handleOpen}
                    startIcon={<InfoIcon style={{fontSize: "30px"}} />}/>
                    <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose} loading={isLoading} data={data}/>
            </Box>
        );
    }
    return(
        <Box>
            <Button style={{
                    color: "#ffffff",
                    backgroundColor: "#0f334a"}}
                    onClick={handleOpen}>
                    Add Zone
                    </Button>
            <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose}/>
        </Box>

    );

}
