import { useNavigate } from "react-router-dom";
import {AppBar, Box, Divider, Toolbar} from "@mui/material";
import Stack from '@mui/material/Stack';
import { Breadcrumb } from "../breadcrumbs/breadcrumbs.component";


export function Navigation(): JSX.Element {
    const navigate = useNavigate();
    return <Box sx={{ width: "100%"}}>
        <AppBar className="appbar" position="static" color="transparent">
            <Toolbar className="toolbar" variant="regular">
                <Box className="logoBackground">
                    <Box className="appbarImgParent" onClick={() => navigate({pathname: `/`})}>
                        <Stack
                            className="stack"
                            spacing={1}
                            padding={2}
                            direction="row"
                            background-color="#0f334a"
                            style={{margin: 0}}
                            divider={<Divider style={{ background: 'white' }} orientation="vertical" flexItem />}
                        >
                            <img
                                className="appbarImg"
                                id="logo-img"
                                src="/img/wartsila.png"
                                alt="Wartsila Logo"
                            />
                            <img
                                className="appbarImg appbarimgLogo"
                                id="logo-img"
                                src="/img/theremin-logo.png"
                                alt="Theremin Logo"
                            />
                        </Stack>
                    </Box>
                </Box>
                <Stack
                    className="stack"
                    spacing={0}
                    padding={1}
                    direction="row"
                    background-color="#0f334a"
                >
                    <Breadcrumb/>
                </Stack>
            </Toolbar>

        </AppBar>
    </Box>
}
