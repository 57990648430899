import {useState} from "react";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { ThreatModelInfo } from "../threatmodel.types";
import { DetailsData } from "../../detailsDialog/detailsDialog.types";
import { getThreatModelInfo } from "../threatmodel.hooks";
import DetailsDialog from "../../detailsDialog/detailsDialog.component";
import { InfoFieldPair } from "../../detailsDialog/detailComponents/detailsDialogComponents.component";


interface ModelFormProps {
    editing: boolean;
    data: ThreatModelInfo;
    nameChecker?: (value: string) => void;
    nameHelperText?: string;
    isValidName?: boolean;
  }


  interface ModelFieldsProps {
    editing: boolean;
    model: ThreatModelInfo | undefined;
    nameChecker?: (value: string) => void;
    nameHelperText?: string;
    isValidName?: boolean;
    addMode: boolean;
  }

export function ModelFields({editing, model, nameChecker, nameHelperText = "", isValidName, addMode}: ModelFieldsProps): JSX.Element {
    return(
      <Box>
        <form id="model-form">
            <InfoFieldPair title="Model Name" name="name" data={model !== undefined ? model.name : ""} updating={false} editing={editing} editable={addMode} valueChanged={nameChecker} helperText={nameHelperText} validValue={isValidName}/>
            <InfoFieldPair title="Description" name="description" data={model !== undefined ? model.description : ""} updating={false} editing={editing}/>
        </form>
      </Box>

    );
  }


  export function ModelForm({editing, data, nameChecker, nameHelperText, isValidName}: ModelFormProps): JSX.Element {
    if (data) {
        return(
          <Box>
            <ModelFields editing={editing} model={data} nameChecker={nameChecker} nameHelperText={nameHelperText} addMode={false}/>
          </Box>
        );
    }
    return(
        <ModelFields editing={editing} model={undefined} nameChecker={nameChecker} nameHelperText={nameHelperText} isValidName={isValidName} addMode/>
    );
  }

interface ModelDialogProps {
    addMode: boolean;
    modelId?: number;
  }


export function ModelDialog({addMode, modelId}: ModelDialogProps): JSX.Element {
    const [dialogOpen, SetDialogOpen] = useState(false);

      const handleOpen = ():void => {
          SetDialogOpen(true);
        };
      const handleClose = ():void => {
          SetDialogOpen(false);
      };
    const detailsData: DetailsData = {
        formName: "model-form",
        path: addMode ? "threatmodels/add" : `threatmodels/update/${modelId}`,
        deletePath: "threatmodels/delete",
        title: addMode ? "Add Model" : "Model Details",
        addMode,
        canEdit: true,
        mode: "dialog",
      };

      if (!addMode && modelId) {
        const { isLoading, data } = getThreatModelInfo(modelId, dialogOpen);
        return(
          <Box>
              <Button
          onClick={handleOpen}
          variant="outlined"
          style={{textTransform: 'none'}}>
          {data ? data.name : ""}
          </Button>
                  <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose} loading={isLoading} data={data}/>
          </Box>
      );
    }
    return(
        <Box>
          <Card className="AddMethodCard" >
                    <CardActionArea onClick={() => {
                        handleOpen()
                    }} sx={{ height: "100%"}}>

                        <CardContent style={{ display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
                            <AddIcon style={{fontSize: "100px"}}/>
                            <Typography gutterBottom variant="h1">
                                Empty Model
                            </Typography>
                    </CardContent>
                    </CardActionArea>
                </Card>
            <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose}/>
        </Box>

    );
  }
