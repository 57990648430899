import { useState } from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { Alert, Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import DetailsDialog from "../detailsDialog/detailsDialog.component";
import { DetailsData } from '../detailsDialog/detailsDialog.types';
import { InfoFieldPair, InfoObjectDropdownPair } from '../detailsDialog/detailComponents/detailsDialogComponents.component';
import { getDeviceInfo } from './device.hooks';
import { getZoneDropdown } from '../zone/zone.hooks';
import { DataflowDialog } from '../dataflow/dataflowDetails/dataflowDetails.component';
import { ComponentDetails } from './device.types';
import { DataflowInfo } from '../dataflow/dataflow.types';



interface DeviceDialogProps {
  addMode: boolean;
  deviceId?: number;
  deviceName?: string;
  zoneId?: number;
}

interface DeviceFormProps {
  editing: boolean;
  data: ComponentDetails;
  modelId: number;
  zoneId: number;
}


interface DeviceFieldsProps {
  editing: boolean;
  device: ComponentDetails | undefined;
  modelId: number;
  zoneId: number;
}

interface DataflowListProps {
  inbound: boolean;
  dataflows: DataflowInfo[];
  deviceId: number;
}

export default function DataflowList({inbound, dataflows, deviceId}: DataflowListProps): JSX.Element {
  return (
    <Box>
      <Box className="detailsButtonBar">
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
        <strong>{inbound ? "Inbound dataflows to this component" : "Outbound dataflows from this component"}</strong>
          </Typography>
          <DataflowDialog addMode inbound={inbound} deviceId={deviceId}/>
      </Box>

      <List className="componentDataflowList">
      {dataflows.map((df) => {
        const labelId = `-${df.src_function_name}`;

        return (
            <ListItem key={df.id} role={undefined} dense
            secondaryAction={
              <DataflowDialog addMode={false} dataflowId={df.id} deviceId={deviceId} inbound={inbound}/>
            }>
            {inbound && (
                  <ListItemText id={labelId} primary={`${df.src_zone_name}: ${df.src_device_name}: ${df.src_function_name} -> ${df.dst_function_name} [${df.link}, ${df.dst_port}, ${df.protocol}]`} />
              )}
            {!inbound && (
                  <ListItemText id={labelId} primary={`${df.src_function_name} -> ${df.dst_zone_name}: ${df.dst_function_name} [${df.link}, ${df.dst_port}, ${df.protocol}]`} />
              )}
          </ListItem>
        );
      })}
      </List>
    </Box>

  );
}

export function DeviceFields({editing, device, modelId, zoneId}: DeviceFieldsProps): JSX.Element {
  const zoneDD = getZoneDropdown(modelId);
  return(
    <Box>
      <form id="device-form">
          <InfoFieldPair title="Label" name="label" data={device !== undefined ? device.name : ""} updating={false} editing={editing}/>
          <InfoFieldPair title="Description" name="description" data={device !== undefined ? device.description : ""} updating={false} editing={editing}/>
          {zoneDD.isLoading && (
                  <Alert severity="info" sx={{mb: 2}}>Loading zones...</Alert>
              )}
          {!zoneDD.isLoading && zoneDD.data && (
                  <InfoObjectDropdownPair title="Zone" name="zone" currentId={device !== undefined ? device.parent_zone_id : zoneId} dropdownOptions={zoneDD.data} updating={false} editing={editing} mandatory/>
              )}

          <InfoFieldPair title="GUID" name="guid" data={device !== undefined ? device.guid : ""} updating={false} editing={false} editable={false} hidden={device === undefined}/>
      </form>
      {device !== undefined && (
        <Box>
          <DataflowList inbound={false} dataflows={device.outbound_df} deviceId={device !== undefined ? device.id : 0}/>
          <DataflowList inbound dataflows={device.inbound_df} deviceId={device !== undefined ? device.id : 0}/>
          </Box>

              )}
    </Box>

  );
}


export function DeviceForm({editing, modelId, data, zoneId}: DeviceFormProps): JSX.Element {
  if (data) {
      return(
        <Box>
          <DeviceFields editing={editing} device={data} modelId={modelId} zoneId={zoneId}/>
        </Box>
      );
  }
  return(
      <DeviceFields editing={editing} device={undefined} modelId={modelId} zoneId={zoneId}/>
  );
}

export function DeviceDialog({addMode, deviceId, deviceName, zoneId}: DeviceDialogProps): JSX.Element {
  const [dialogOpen, SetDialogOpen] = useState(false);

    const handleOpen = ():void => {
        SetDialogOpen(true);
      };
    const handleClose = ():void => {
        SetDialogOpen(false);
    };
  const detailsData: DetailsData = {
      formName: "device-form",
      path: addMode ? "architecture/device/add" : `architecture/device/update/${deviceId}`,
      deletePath: "architecture/device/delete",
      title: addMode ? "Add Device" : "Device Details",
      addMode,
      zoneId: zoneId !== undefined ? zoneId : -1,
      canEdit: true,
      mode: "dialog",
    };

    if (!addMode && deviceId) {
      const { isLoading, data } = getDeviceInfo(deviceId, dialogOpen);
      return(
        <Box>
            <Button
        onClick={handleOpen}
        variant="outlined"
        startIcon={<ComputerIcon />}
        style={{textTransform: 'none'}}>
        {deviceName}
        </Button>
                <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose} loading={isLoading} data={data}/>
        </Box>
    );
  }
  return(
      <Box>
        <Button
          onClick={handleOpen}
          startIcon={<AddCircleIcon style={{fontSize: "30px"}} />}/>
          <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose}/>
      </Box>

  );
}
