import { ProductInfo } from "../product/product.types";
import { ThreatModelInfo } from "../threatmodel/threatmodel.types";

export function setSessionValue(key: string, value: string): void{
    sessionStorage.setItem(key, value);
}

export function getSessionValue(key: string): any{
    return sessionStorage.getItem(key);
}

export function removeItemFromSession(key: string): void{
    sessionStorage.removeItem(key);
}

export function setCurrentSessionProduct(product: ProductInfo | undefined): void {
    if (product !== undefined) {
        setSessionValue("Product", JSON.stringify(product));
    }
}

export function getCurrentProduct(): ProductInfo | undefined {
    const tempData = getSessionValue("Product");
    const product: ProductInfo = JSON.parse(tempData);
    return product;
}

export function setCurrentSessionThreatModel(product: ThreatModelInfo | undefined): void {
    if (product !== undefined) {
        setSessionValue("ThreatModel", JSON.stringify(product));
    }
}

export function getCurrentThreatModel(): ThreatModelInfo | undefined {
    const tempData = getSessionValue("ThreatModel");
    const model: ThreatModelInfo = JSON.parse(tempData);
    return model;
}
