import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { ThreatModelInfo } from "../threatmodel.types";
import { DetailsData } from "../../detailsDialog/detailsDialog.types";
import DetailsDialog from "../../detailsDialog/detailsDialog.component";

interface InformationTabProps {
    index: number;
    value: number;
    data: ThreatModelInfo;
}


interface VerticalTabProps {
  data: ThreatModelInfo;
}


interface TabPanelProps {
    index: number;
    value: number;
    type: string;
    data: ThreatModelInfo;
}


export function TabPanel({ value, index, type, data}: TabPanelProps): JSX.Element {
  const detailsData: DetailsData = {
    formName: "model-form",
    path: `threatmodels/update/${data.id}`,
    deletePath: "threatmodels/delete",
    title: "Model Details",
    addMode: false,
    canEdit: true,
    mode: "page",
  };

  if (value === index) {
        return (
          <Box minWidth="100%" sx={{ p: 3 }}>
            {type === "information" && (
                <DetailsDialog detailsData={detailsData} panelOpen data={data}/>
            )}
            {type === "notes" && (
                <Typography>Not implemented yet</Typography>
            )}
          </Box>
        );
    }
    return (
        <Box/>
    );
}

export default function VerticalTabs({data}: VerticalTabProps): JSX.Element   {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number):void => {
      setValue(newValue);
    };

    return (
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Architecture tabs"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: 100 }}
        >
          <Tab label="Basic Information"/>
          <Tab label="Summary Notes" />
        </Tabs>
        <TabPanel value={value} index={0} type="information" data={data}/>
        <TabPanel value={value} index={1} type="notes" data={data}/>
      </Box>
    );
  }

export function InformationTabPanel({value, index, data }: InformationTabProps): JSX.Element  {

    return (
        <Container maxWidth="xl">
            {value === index && (
                <Box sx={{ paddingTop: 3 }}>
                    <VerticalTabs data={data}/>
                </Box>
                )}
        </Container>

    );
  }
