import { useState } from 'react';
import Button from '@mui/material/Button';
import { Alert, Box, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DetailsDialog from "../../detailsDialog/detailsDialog.component";
import { DetailsData } from '../../detailsDialog/detailsDialog.types';
import { InfoDropdownPair, InfoFieldPair, InfoObjectDropdownPair } from '../../detailsDialog/detailComponents/detailsDialogComponents.component';
import { DataflowInfo } from '../dataflow.types';
import { getSourceDropdown, useDataflowInfo } from '../dataflow.hooks';



interface DataflowDialogProps {
  addMode: boolean;
  dataflowId?: number;
  inbound: boolean;
  deviceId: number;
}

interface DataflowFormProps {
  editing: boolean;
  data: DataflowInfo;
  modelId: number;
  inbound: boolean;
  deviceId: number;
}


interface DataflowFieldsProps {
  editing: boolean;
  dataflow: DataflowInfo | undefined;
  modelId: number;
  inbound: boolean;
  deviceId: number;
}

const LinkTypes = [
  "UDP",
  "Serial",
  "TCP",
  "Other",
]

const Active = [
 "Normal operation",
  "Commissioning/service",
  "Not in scope",
]

export function DataflowFields({editing, dataflow, modelId, inbound, deviceId}: DataflowFieldsProps): JSX.Element {
  const sourceDD = getSourceDropdown(modelId);
  return(
      <form id="dataflow-form">
        {sourceDD.isLoading && (
                  <Alert severity="info" sx={{mb: 2}}>Loading data...</Alert>
              )}
        {!sourceDD.isLoading && sourceDD.data && (
          <Box>
            <Box style={{float: "left", width: "45%"}}>
                <InfoObjectDropdownPair title="Source" name="source" currentId={dataflow !== undefined ? dataflow.src_device_id : deviceId} dropdownOptions={sourceDD.data} updating={false} editing={editing} mandatory editable={inbound}/>
                <InfoFieldPair title="Source process" name="sourceProcess" data={dataflow !== undefined ? dataflow.src_function_name : ""} updating={false} editing={editing} mandatory/>
                <InfoDropdownPair title="Link Type" name="link" data={dataflow !== undefined ? dataflow.link : ""} dropdownOptions={LinkTypes} updating={false} editing={editing} mandatory/>
                <InfoFieldPair title="Application protocol" name="protocol" data={dataflow !== undefined ? dataflow.protocol : ""} updating={false} editing={editing}/>
                </Box>
                <Box style={{float: "right", width: "45%"}} sx={{ml: 3}}>
                <InfoObjectDropdownPair title="Destination" name="destination" currentId={dataflow !== undefined ? dataflow.dst_device_id : deviceId} dropdownOptions={sourceDD.data} updating={false} editing={editing} mandatory editable={!inbound}/>
                <InfoFieldPair title="Destination process" name="destinationProcess" data={dataflow !== undefined ? dataflow.dst_function_name : ""} updating={false} editing={editing} mandatory/>
                <InfoDropdownPair title="Active" name="active" data={dataflow !== undefined ? dataflow.active : ""} dropdownOptions={Active} updating={false} editing={editing} mandatory/>
                <InfoFieldPair title="Destination port" name="destinationPort" data={dataflow !== undefined ? dataflow.dst_port : ""} updating={false} editing={editing}/>
                </Box>
                <InfoFieldPair title="Description" name="description" data={dataflow !== undefined ? dataflow.description : ""} updating={false} editing={editing} multiline/>
          </Box>

        )}
          <InfoFieldPair title="GUID" name="guid" data={dataflow !== undefined ? dataflow.guid : ""} updating={false} editing={false} editable={false} hidden={dataflow === undefined}/>
      </form>
  );
}


export function DataflowForm({editing, data, modelId, inbound, deviceId}: DataflowFormProps): JSX.Element {
  if (data) {
      return(
          <Box>
              <DataflowFields editing={editing} dataflow={data} modelId={modelId} inbound={inbound} deviceId={deviceId}/>
          </Box>
      );
  }
  return(
      <DataflowFields editing={editing} dataflow={undefined} modelId={modelId} inbound={inbound} deviceId={deviceId}/>
  );
}

export function DataflowDialog({addMode, dataflowId, inbound, deviceId}: DataflowDialogProps): JSX.Element {
  const [dialogOpen, SetDialogOpen] = useState(false);

    const handleOpen = ():void => {
        SetDialogOpen(true);
      };
    const handleClose = ():void => {
        SetDialogOpen(false);
    };
  const detailsData: DetailsData = {
      formName: "dataflow-form",
      path: addMode ? "dataflow/add" : "dataflow/update",
      deletePath: "dataflow/delete",
      title: addMode ? "Add dataflow" : "Dataflow Details",
      addMode,
      inbound,
      deviceId,
      mode: "dialog",
    };
    if (!addMode && dataflowId) {
      const { isLoading, data } = useDataflowInfo(dataflowId, dialogOpen);
      return(
        <Box>
            <IconButton edge="end" aria-label="dataflowDetails" onClick={handleOpen}>
                <InfoIcon />
              </IconButton>
            <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose} loading={isLoading} data={data}/>
        </Box>
    );
  }
  return(
      <Box>
        <Button style={{
              color: "#ffffff",
              backgroundColor: "#0f334a"}}
              onClick={handleOpen}>
              Add...
              </Button>
          <DetailsDialog detailsData={detailsData} panelOpen={dialogOpen} onPanelClose={handleClose}/>
      </Box>

  );
}
