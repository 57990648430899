import React, {FormEvent} from "react";
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {Alert, Box, Button, CircularProgress, Container, Input} from "@mui/material";
import axios, {AxiosResponse} from "axios";
import {useAuth} from "../auth/auth.hooks";
import {Banner} from "../banner/banner.component";

interface UpdateState {
    fileText: string;
    uploadStatus: string;
    uploadIndicator: string;
    fileButton: boolean;
    uploadButton: boolean;
}

export const withHooksHOC = (Component: React.ElementType) => function withHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient}/>;
};

interface IHooksHOCProps {
    accessToken: string;
    config: Record<string, unknown>;
    queryClient: QueryClient;
}

type AxiosCallback = (r: AxiosResponse) => void;

class Upload extends React.Component<IHooksHOCProps, UpdateState> {

    // Return upload button JSX element based on enabled state
    static UploadButton = (enabled: boolean): JSX.Element => (
        <Button
            style={{ color: "#ffffff", backgroundColor: enabled ? "#0f334a" : "#99b7c4" }}
            sx={{m: 0.5}}
            type="submit"
            variant="contained"
            disabled={ !enabled }>
            Upload
        </Button>
    )

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            fileText: "No file selected",
            uploadStatus: "nofile",
            uploadIndicator: "hidden",
            fileButton: true,
            uploadButton: false,
        }
        this.sendFile = this.sendFile.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.DisplayStatusMessage = this.DisplayStatusMessage.bind(this);
        this.inputFileChanged = this.inputFileChanged.bind(this);
        Upload.UploadButton = Upload.UploadButton.bind(this);
    }

    sendFile = (object: File, modelId: number, type: string, accessToken: string, conf: Record<string, unknown>, callback: AxiosCallback): void => {
        const form = new FormData();
        form.append("file", object);
        form.append("fileType", type);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${accessToken}`
            },
            params: {
                model_Id: modelId
            }
        }

        axios.post(`${conf.REACT_APP_API_BASE_URL}/${conf.REACT_APP_ENVIRONMENT}/dataflow`, form, config)
            .then((response) => {
                callback(response);
            }).catch(error => {
                if(!error.response) {
                    this.setState({
                        uploadStatus: "no-connection",
                        uploadIndicator: "hidden",
                        fileButton: true,
                        uploadButton: true,
                    })
                }
                else {
                    this.setState({
                        uploadStatus: "fail",
                        uploadIndicator: "hidden",
                        fileButton: true,
                        uploadButton: true,
                    })
                }
        })
    }

    // Process upload button press
    submitForm = (e: FormEvent<HTMLFormElement>): boolean => {
        const {accessToken} = this.props;
        const {config} = this.props;
        // Callback for when response from POST request is received
        const cb = (response: AxiosResponse): void => {
            if (response.status === 200) {
                this.setState({
                    uploadStatus: "success",
                    uploadIndicator: "hidden",
                    fileButton: true,
                    uploadButton: false,
                });
                const {queryClient} = this.props;
                queryClient.invalidateQueries(['dataflow']);
                queryClient.invalidateQueries(['dataflow_rows']);
                queryClient.invalidateQueries(['architecture']);
                queryClient.invalidateQueries(['dataflow']);
            } else {
                this.setState({
                    uploadStatus: "fail",
                    uploadIndicator: "hidden",
                    fileButton: true,
                    uploadButton: true,
                });
            }
        }

        e.preventDefault();

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const modelId = +parts[3];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const inputFiles = document.getElementById("input").files;

        // Check if a file is selected
        if (inputFiles.length > 0) {
            this.setState({
                uploadStatus: "uploading",
                uploadIndicator: "block",
                fileButton: false,
                uploadButton: false,
            });

            // Create the POST request and send the file
            this.sendFile(inputFiles[0], modelId, "", accessToken, config, cb);

            return true;
        }

        // No file selected
        this.setState({
            uploadIndicator: "hidden"
        });

        return false;
    }

    // Display a status message on the page based on upload status
    DisplayStatusMessage = (): JSX.Element => {
        const {uploadStatus} = this.state;
        const {fileText} = this.state;
        switch (uploadStatus) {
            case "nofile":
                return (
                    <Alert severity="info" sx={{mb: 2}}>No file selected</Alert>
                );
            case "file":
                return (
                    <Alert severity="info" sx={{mb: 2}}>{fileText}</Alert>
                );
            case "uploading":
                return (
                    <Alert severity="info" sx={{mb: 2}}>Uploading file...</Alert>
                );
            case "success":
                return (
                    <Alert severity="success" sx={{mb: 2}}>File uploaded successfully</Alert>
                );
            case "fail":
                return (
                    <Alert severity="error" sx={{mb: 2}}>Failed to upload file</Alert>
                );
            case "wrong-type":
                return (
                    <Alert severity="error" sx={{mb: 2}}>Selected file is incorrect type</Alert>
                );
            case "no-connection":
                return (
                    <Alert severity="error" sx={{mb: 2}}>Could not upload file due to connection issue</Alert>
                );
            case "no-model":
            return (
                <Alert severity="error" sx={{mb: 2}}>No model selected</Alert>
            );
            default:
                return (
                    <Alert style={{display: "none"}} sx={{mb: 2}}>Hidden</Alert>
                );
        }
    }

    // Change upload button state and filename text when file dialog is closed
    inputFileChanged(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const inputFiles = document.getElementById("input").files;

        if (inputFiles.length > 0) {
            const fileExt = inputFiles[0].name.split('.').pop();

            // Check file extension
            if (fileExt === "xlsx" || fileExt === "txt") {
                this.setState({
                    fileText: inputFiles[0].name,
                    uploadStatus: "file",
                    uploadIndicator: "none",
                    uploadButton: true
                });
            } else {
                // File is wrong type
                this.setState({
                    fileText: inputFiles[0].name,
                    uploadStatus: "wrong-type",
                    uploadButton: false
                });
            }
        } else {
            this.setState({
                fileText: "No file selected",
                uploadButton: false
            })
        }
    }

    // Render the page
    render(): JSX.Element {
        const {uploadIndicator} = this.state;
        const {fileButton} = this.state;
        const {uploadButton} = this.state;

        const IndicatorDisplay = {
            display: "inline-block",
            height: 25,
            width: 25,
            verticalAlign: "middle"
        }

        const IndicatorHidden = {
            display: "none",
            height: 25,
            width: 25,
            verticalAlign: "middle"
        }

        const IndicatorStatus = uploadIndicator === "block" ? IndicatorDisplay : IndicatorHidden;

        return (
            <Box>
                <Banner firstLine="Dataflow Data" secondLine="Upload" alignToLeft/>
                <Container maxWidth="xl">
                    <div>
                        {this.DisplayStatusMessage()}
                        <form onSubmit={this.submitForm}>
                            <br/>
                            <label htmlFor="input">
                                <Input
                                    onChange={this.inputFileChanged}
                                    style={{display: "none"}}
                                    inputProps={{accept: ".xlsx"}}
                                    type="file"
                                    id="input"
                                    name="filename"
                                />
                                <Button
                                    style={{ color: "#ffffff", backgroundColor: fileButton ? "#0f334a" : "#99b7c4" }}
                                    sx={{m: 0.5}}
                                    variant="contained"
                                    component="span"
                                    disabled={ !fileButton }>
                                    Select a file
                                </Button>
                            </label>
                            <label htmlFor="upload-file">
                                {Upload.UploadButton(uploadButton)}
                            </label>
                            <CircularProgress
                                sx={{m: 1}}
                                style={IndicatorStatus}
                            />
                        </form>
                    </div>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(Upload);
