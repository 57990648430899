import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { ZoneInfo } from './zone.types';
import { DropDownItem } from '../detailsDialog/detailsDialog.types';


export const getZoneInfo= (zoneId: number, enabled: boolean): UseQueryResult<ZoneInfo> => {
    const { accessToken, config } = useAuth();

    return useQuery(
      ['zone', zoneId, enabled],
      () =>
        axios
          .get<ZoneInfo>(
            `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture/zone`,
            { params: {
              zone_id: zoneId,
              }, headers: { Authorization: `Bearer ${accessToken}` } }
          )
          .then((response) => response.data),
      {
        staleTime: 60000,
        refetchOnWindowFocus: true,
        enabled: Boolean(accessToken) && enabled,
      }
    );
};

export const getZoneDropdown= (modelID: number): UseQueryResult<DropDownItem[]> => {
  const { accessToken, config } = useAuth();

  return useQuery(
    ['zone', modelID],
    () =>
      axios
        .get<DropDownItem[]>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/architecture/zone/dd`,
          { params: {
            model_id: modelID,
            }, headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data),
    {
      staleTime: 60000,
      refetchOnWindowFocus: true,
      enabled: Boolean(accessToken),
    }
  );
};
