import {NavLink} from "react-router-dom";
import {Box} from "@mui/material";

interface ButtonBaseProps {
    text: string;
    path: string;
}

export function ButtonBase({text, path}: ButtonBaseProps): JSX.Element | null {
    return <Box className="navigationButton" style={{
        paddingTop: 40, paddingBottom: 10
    }}>
        {/* This is not a MUI component, so it can't be styled with the global theme in index.tsx */}
        <NavLink end to={path}
                 style={(navData) => ({
                     fontFamily: "Noto Sans",
                     color: "#ff7321",
                     marginTop: 0,
                     marginLeft: 0,
                     marginRight: 0,
                     marginBottom: 0,
                     paddingTop: 22,
                     paddingLeft: 10,
                     paddingRight: 10,
                     paddingBottom: 17,
                     fontSize: 20,
                     fontWeight: navData.isActive ? "normal" : "lighter",
                     textTransform: "capitalize",
                     textDecoration: navData.isActive ? "underline" : "none",
                     textUnderlineOffset: 15,
                     textDecorationThickness: navData.isActive ? 5 : 0,
                 })}>
            <span style={{color: "#0f172b"}}>
                {text}
            </span>
        </NavLink>
    </Box>
}
