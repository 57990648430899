export const searchCache: {[key: string]: string[] | undefined;} = {};

export function searchCacheGet(key: string): string[] | undefined {
    // Make sure key exists
    if(key in searchCache) {
        return searchCache[key];
    }

    return undefined;
}

export function searchCacheSet(key: string, value: string[] | undefined): void {
    searchCache[key] = value;
}
